import CookieControl from 'components/Google/CookieControl';
import Routes from './routes';

function App() {
  return (
    <>
      <Routes/>
      <CookieControl></CookieControl>
    </>
  )
}
export default App;
