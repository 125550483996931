/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";

import {
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";

import api from "../../../services/api";
import { BeatLoader } from "react-spinners";

export default function ServerCreate(props) {
  const [name, setName] = React.useState('')
  const [icon, setIcon] = React.useState('')
  const [createServers, setCreateServers] = React.useState('')

  const [loader, setLoader] = React.useState(false)

  useEffect(() => {
    if (props.onChange) {
      props.onChange([createServers])
    }
    // eslint-disable-next-line
  }, [createServers])


  const serverSubmit = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams()
    params.append('name', name)
    params.append('icon', icon)
    params.append('position', props.position)

    await api.post('server', params, {
        onUploadProgress: () => {
          setLoader(true)
        }
      }).then(() => {
        setCreateServers(true)
        setLoader(false)
      }
    )
    setLoader(false)
  }

  return (
    <>
      <Row>
        <Col md="6">
          <Label className="text-left text-dark" htmlFor="create-name">
            Nome
          </Label>
          <input id="create-name" onChange={(e) => setName(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Nome"/>
        </Col>
        <Col md="6">
          <Label className="text-left text-dark" htmlFor="create-icon">
            Icone
          </Label>
          <input id="create-icon" onChange={(e) => setIcon(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Icone"/>
        </Col>
      </Row>
      {loader ?
          <BeatLoader margin={20} color={'#3eda96'} loading={true} size={10} />
        :
          <Button className="btn btn-success" onClick={serverSubmit} itemType="submit">Confirmar</Button>
      }
    </>
  );
}
