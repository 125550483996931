/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
// reactstrap components
import {
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";

// core components
import api from "../../../services/api";
import { BeatLoader } from "react-spinners";
import Select from "react-select";
import makeAnimated from 'react-select/animated';


export default function CategoryCreate(props) {

  const [name, setName] = React.useState('')
  const [icon, setIcon] = React.useState('')
  const [servers, setServers] = React.useState([])
  const [serverId, setServerId] = React.useState('')
  const [createCategories, setCreateCategories] = React.useState('')
  const animatedComponents = makeAnimated();

  const [loader, setLoader] = React.useState(false)

  useEffect(() => {
    if (props.onChange) {
      props.onChange([createCategories])
    }
    getServers()
    // eslint-disable-next-line
  }, [createCategories])


  const categorySubmit = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams()
    params.append('name', name)
    params.append('icon', icon)
    params.append('serverId', serverId)
    params.append('position', props.position)

    await api.post('category', params, {
        onUploadProgress: () => {
          setLoader(true)
        }
      }).then(() => {
        setCreateCategories(true)
        setLoader(false)
      }
    )
    setLoader(false)
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      color: '#000',
    }),
  }

  const getServers = () => {
    api.get('server-list').then((response) => {
      var listServers = []
      response.data.map((value, i) => {
        return listServers[i] = { value: value.id, label: value.name }
      })
      setServers(listServers)
    })
  }

  return (
    <>
      <div className="author mt-0">
        <a href="#a" onClick={(e) => e.preventDefault()}>
          <Row className="text-left p-0">
            <Col md="4">
              <Label className="text-dark">
                Nome
              </Label>
              <input onChange={(e) => setName(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Nome"/>
            </Col>
            <Col md="4">
              <Label className="text-left text-dark">Icone</Label>
              <input onChange={(e) => setIcon(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Icone"/>
            </Col>
            <Col md="4">
              <Label className="text-left">
                <span className="text-gray-800">Servidores</span>
              </Label>
              <Select
                placeholder="Servidores"
                onChange={e => {setServerId(e.value)}}
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={servers}
                styles={customStyles}
              />
            </Col>
          </Row>
        </a>
        {loader ?
            <BeatLoader margin={20} color={'#3eda96'} loading={true} size={10} />
          :
            <Button className="btn btn-success" itemType="submit" onClick={categorySubmit}>Confirmar</Button>
        }
      </div>
    </>
  );
}
