/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Row,
  Col,
  Container,
  CardBody,
  CardHeader,
  Card,
} from "reactstrap";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";


export default function AdsTerms() {
  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };
  },[]);

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section mt-44 mb-20">
          <img alt="path3" className="path" src={require("assets/img/path4.png").default}/>
          <Container>
            <Card className="bg-white">
              <CardHeader>
                <h2 className="text-dark text-lg">Política de Cookies da {process.env.REACT_APP_WEBSITE_NAME}</h2>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <h2 className="text-dark text-lg mt-2">Última modificação: 6 de dezembro de 2022</h2>
                    <p className="text-dark mb-2">
                      Esta Política de Cookies explica como a {process.env.REACT_APP_WEBSITE_NAME} usa cookies para reconhecê-lo quando você visita nossos sites (bclcraft.casacam.net). Ele explica o que são e por que usamos, bem como seus direitos de controlar nosso uso delas.<br/>
                    </p>
                  </Col>
                  <Col md="12">
                    <h2 className="text-dark text-lg">O que são cookies?</h2>
                    <p className="text-dark mb-2">
                      Um cookie é um pequeno arquivo contendo uma sequência de caracteres que é enviada ao seu computador quando você visita um site. Quando você visita o site novamente, o cookie permite que o site reconheça seu navegador. Os cookies podem armazenar as preferências do usuário e outras informações. Os cookies fornecem um recurso conveniente para economizar seu tempo ou informam ao servidor da Web que você retornou a uma página específica.
                    </p>
                    <p className="text-dark mb-2">
                      Os cookies definidos pelo proprietário do site (neste caso, {process.env.REACT_APP_WEBSITE_NAME}) são chamados de "cookies primários". Os cookies definidos por outras partes que não o proprietário do site são chamados de "cookies de terceiros".
                    </p>
                    <p className="text-dark mb-2">
                      Os cookies de terceiros permitem que recursos ou funcionalidades de terceiros sejam fornecidos no site ou por meio dele (por exemplo, publicidade, conteúdo interativo e análises). As partes que definem esses cookies de terceiros podem reconhecer seu computador quando ele visita o site em questão e também quando visita outros sites.
                    </p>
                  </Col>
                  <Col md="12">
                    <h2 className="text-dark text-lg">Por que usamos cookies?</h2>
                    <p className="text-dark mb-2">
                      Usamos cookies primários e de terceiros por vários motivos. Aceitando os cookeis você poderá ver propagandas e participar de eventos no site. Alguns cookies são necessários por motivos técnicos para que nosso site funcione, e nos referimos a eles como cookies "essenciais" ou "estritamente necessários". Outros cookies também nos permitem rastrear e direcionar os interesses de nossos usuários para aprimorar a experiência. Por exemplo, a {process.env.REACT_APP_WEBSITE_NAME} rastreia os sites e páginas que você visita para determinar qual parte do site ou serviço de assinatura é a mais popular ou mais usada. Esses dados são usados ​​para fornecer conteúdo personalizado de promoções e serviços de assinatura para clientes cujo comportamento indica que eles estão interessados. Terceiros fornecem cookies por meio de nossos sites para publicidade, análises e outros propósitos. Isso é descrito com mais detalhes abaixo.
                    </p>
                  </Col>
                  <Col md="12">
                    <h2 className="text-dark text-lg">Que tipos de cookies usamos e como os usamos?</h2>
                    <p className="text-dark mb-2">
                      Os tipos específicos de cookies primários e de terceiros veiculados por meio de nossos sites e as finalidades que eles desempenham. Para obter uma lista dos cookies usados ppela {process.env.REACT_APP_WEBSITE_NAME}, consulte esta página . Esses cookies incluem:
                    </p>
                    <p className="text-dark mb-2">
                      Cookies essenciais do site: Esses cookies são estritamente necessários para fornecer serviços disponíveis por meio de nossos sites.
                    </p>
                    <p className="text-dark mb-2">
                      Cookies de publicidade: Esses cookies são usados ​​para tornar as mensagens de publicidade mais relevantes para você. Eles executam funções como impedir que o mesmo anúncio reapareça continuamente, garantindo que os anúncios sejam exibidos adequadamente para os anunciantes e, em alguns casos, selecionando anúncios com base em seus interesses.
                    </p>
                  </Col>
                  <Col md="12">
                    <h2 className="text-dark text-lg">Você veicula publicidade direcionada?</h2>
                    <p className="text-dark mb-2">
                      Terceiros podem fornecer cookies em seu computador ou dispositivo móvel para veicular publicidade em nossos sites. Essas empresas podem usar informações sobre suas visitas a este e outros sites para fornecer anúncios relevantes sobre produtos e serviços nos quais você possa estar interessado. Elas também podem empregar tecnologia usada para medir a eficácia dos anúncios. Isso pode ser feito por eles usando cookies para coletar informações sobre suas visitas a este e outros sites, a fim de fornecer anúncios relevantes sobre produtos e serviços de interesse potencial para você. As informações coletadas por meio deste processo não permitem que nós ou eles identifiquemos seu nome, detalhes de contato ou outros detalhes de identificação pessoal, a menos que você opte por fornecê-los.
                    </p>
                    <h2 className="text-dark text-lg mt-2">Com que frequência você atualizará esta Política de Cookies?</h2>
                    <p className="text-dark mb-2">
                      Podemos atualizar esta Política de Cookies de tempos em tempos para refletir, por exemplo, alterações nos cookies que usamos ou por outros motivos operacionais, legais ou regulatórios. Publicaremos as alterações nesta página. Portanto, visite novamente esta Política de Cookies regularmente para se manter informado sobre nosso uso de cookies e tecnologias relacionadas.
                      A data no topo desta Política de Cookies indica quando ela foi atualizada pela última vez.
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
