/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import api from "services/api";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import ToastNotify from "utils/toasts";

let ps = null;

export default function ProfilePage() {
  const [user, setUser] = React.useState()
  const [firstName, setFirstName] = React.useState()
  const [lastName, setLastName] = React.useState()
  const [password, setPassword] = React.useState()
  const [email, setEmail] = React.useState()
  const [loader, setLoader] = React.useState()
  const [picture_url, setPicture_url] = React.useState(process.env.REACT_APP_API_URL + '/' + user?.picture_url ?? '')
  const [picture_url_reference, setPicture_url_reference] = React.useState(null)
  const fileInputSend = React.useRef(null);

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    document.body.classList.toggle("profile-page");

    getAuthUSer()

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
      document.body.classList.toggle("profile-page");
    };
  },[]);


  const getAuthUSer = async () => {
    await api.get('user').then((response)=> {
      setFirstName(response.data.firstName)
      setLastName(response.data.lastName)
      setEmail(response.data.email)
      setUser(response.data)
      setPicture_url(process.env.REACT_APP_API_URL + '/' + response.data.picture_url)
    })
  }

  const updateUser = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('firstName', firstName)
    form.append('lastName', lastName)
    form.append('email', email)
    form.append('password', password)
    if (picture_url_reference){
      form.append('picture_url', picture_url_reference, picture_url_reference.name)
    }
    setLoader(true)
    await api.put(`user/${user.id}`, form, {
      onUploadProgress: () => {
        setLoader(true)
      }
    }).then(() => {
      setLoader(false)
      toast.success("Usuário atualizado com sucesso!")
      getAuthUSer()
    }).catch(() => {
      setLoader(false)
    })
  }

  const handleImageChange = async (e) => {
    setPicture_url_reference(e.target.files[0])
    setPicture_url(URL.createObjectURL(e.target.files[0]))
  };

  return (
    <>
      <ToastNotify/>
      <AdminNavbar />
      <div className="wrapper">
        <div className="page-header">
          <Container className="align-items-center">
            <Row>
              <Col className="ml-auto mr-auto" lg="12" md="12">
                <Card className="card-coin card-plain">
                  <CardHeader>
                    <img
                      alt="..."
                      className="img-center img-fluid rounded-circle cursor-pointer"
                      src={picture_url ?? default_avatar}
                      onClick={() => fileInputSend.current.click()}
                    />
                    <div className="text-center">
                      <input
                        type="file"
                        id="image"
                        accept="image/png, image/jpeg, image/gif, image/webp"
                        onChange={handleImageChange}
                        ref={fileInputSend}
                        hidden
                      />
                    </div>
                    {user &&
                      <h4 className="title">{user.firstName + ' ' + user.lastName}</h4>
                    }
                  </CardHeader>
                  <CardBody>
                  <Card className="card-plain mb-0">
                    <CardBody>
                      {user &&
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <label>Primeiro nome</label>
                              <Input defaultValue={user.firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="Primeiro nome" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Segundo nome</label>
                              <Input defaultValue={user.lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Segundo nome" type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Email</label>
                              <Input defaultValue={user.email} onChange={(e) => setEmail(e.target.value)} placeholder="example@email.com"  type="text" />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label>Senha</label>
                              <Input defaultValue={user.password} onChange={(e) => setPassword(e.target.value)} placeholder="Senha" type="password" />
                            </FormGroup>
                          </Col>
                          <Col md="12" className="text-right mt-2">
                            {loader ?
                              <BeatLoader margin={10} color={'#3eda96'} loading={true} size={15} />
                            :
                              <Button
                              className="btn-round float-right"
                              color="success"
                              data-placement="right"
                              id="tooltip341148792"
                              type="button"
                              onClick={updateUser}
                              >
                                Salvar
                              </Button>
                            }
                          </Col>
                        </Row>
                      }

                    </CardBody>
                  </Card>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
