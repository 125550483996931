/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";

import api from "../../../services/api";
import { BeatLoader } from "react-spinners";
import { Switch } from '@headlessui/react';
import { diffDate } from '../../../utils/dateControl';
import DatePicker from "react-datetime";
import moment from "moment";
import { generate, charset } from 'voucher-code-generator';

import 'moment/locale/pt-br';
moment.locale('pt-br');
export default function CouponCreate(props) {

  const [loader, setLoader] = React.useState(false)
  const [createCoupons, setCreateCoupons] = React.useState('')
  const [enabled, setEnabled] = React.useState(true)
  const [code, setCode] = React.useState('')
  const [percent, setPercent] = React.useState('')
  const [quantity, setQuantity] = React.useState('')
  // const [status, setStatus] = React.useState('')
  const [expirationDate, setExpirationDate] = React.useState('')

  const toggle = () => {
    setEnabled(!enabled)
  }

  useEffect(() => {
    if (props.onChange) {
      props.onChange([createCoupons])
    }
    // eslint-disable-next-line
  }, [createCoupons])

  const couponSubmit = async (e) => {
    e.preventDefault();
    const form = new URLSearchParams();
    form.append('code', code)
    form.append('percent', percent)
    form.append('quantity', quantity)
    if(enabled){
      form.append('status', parseInt(1))
    }else{
      form.append('status', parseInt(2))
    }
    form.append('expirationDate', expirationDate)
    createCoupon(form)
    setLoader(false)
  }

  const createCoupon = async (form) => {
    await api.post('coupon', form, {
      onUploadProgress: () => {
        setLoader(true)
      }
    }).then(() => {
      setLoader(false)
      setCreateCoupons(true)
    }
    ).catch(() => {
      setLoader(false)
    })
  }

  let inputProps = {
    placeholder: 'Data de expiração',
    disabled: false,
    className: 'w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline',
  }

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = current => {
    return current.isAfter(yesterday);
  };

  const codeGenerator  = () => {
    return generate({
      length: 16,
      charset: charset("alphanumeric"),
      pattern: "####-####-####-####",
    }).toString();
  }
  const updateCode = (e) => {
    e.preventDefault()
    setCode(codeGenerator().toUpperCase())
  }

  return (
    <>
      <Row>
        <Col md="12">
        <Label className="text-left">
            <span className="text-gray-800">Código</span>
          </Label>
          <div className="d-flex items-center">
            <input value={code} onChange={(e) => setCode(e.target.value)} className="mr-2 w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Código"/>
            <button type="button" onClick={(e) => updateCode(e)} className="focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 bg-gray-500 hover:bg-gray-700 text-white font-bold py-0 px-1 rounded-full">
              <i className="fas fa-sync-alt"></i>
            </button>
          </div>
        </Col>
        <Col md="6" className="mb-2">
          <Label className="text-left">
            <span className="text-gray-800">Percentual</span>
          </Label>
          <input onChange={(e) => setPercent(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="number" placeholder="Desconto"/>
        </Col>
        <Col md="6" className="w-full">
          <Label className="text-left">
            <span className="text-gray-800">Quantidade</span>
          </Label>
          <input onChange={(e) => setQuantity(e.target.value)} className="text-gray-800 w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="number" placeholder="Quantidade"/>
        </Col>
        <Col md="12" className="w-full mb-3">
          <Label className="text-left">
            <span className="text-gray-800">Data de expiração</span>
          </Label>
          <DatePicker
            isValidDate={disablePastDt}
            onChange={setExpirationDate}
            inputProps={inputProps}
            dateFormat="DD-MM-YYYY"
            locale="pt-br"
          />
          <span className={`${diffDate(expirationDate) && diffDate(expirationDate).split('expirado').length > 1 ? 'text-red-600' : 'text-green-600'}`}>
            {diffDate(expirationDate)}
          </span>
        </Col>
        <Col md="6">
          <Label className="text-left">
            <span className="text-gray-800">Status</span>
          </Label>
            <Switch.Group>
            <div className="flex items-center">
              <Switch
                checked={enabled}
                onChange={toggle}
                className={`${
                  enabled ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
              >
                <span
                  className={`${
                    enabled ? 'translate-x-6' : 'translate-x-1'
                  } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                />
              </Switch>
            </div>
          </Switch.Group>
        </Col>
      </Row>
      <Row className="text-right">
        <Col md="12">
          {loader ?
              <BeatLoader margin={10} color={'#3eda96'} loading={true} size={15} />
            :
              <Button onClick={couponSubmit} className="btn btn-success m-0" itemType="submit">
                Confirmar
              </Button>
          }
        </Col>
      </Row>
    </>
  );
}
