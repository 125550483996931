/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Button,
  Label,
} from "reactstrap";

import api from "../../../services/api";
import { BeatLoader } from "react-spinners";
import default_avatar from "../../../assets/img/default-avatar.png";
import { toast } from "react-toastify";

export default function UserCreate(props) {

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [picture_url_reference, setPicture_url_reference] = React.useState(null)
  const [picture_url, setPicture_url] = React.useState(null)
  const [email, setEmail] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const fileInputSend = React.useRef(null);
  const [createUsers, setCreateUsers] = React.useState('')
  const [password, setPassword] = React.useState('')

  useEffect(() => {
    if (props.onChange) {
      props.onChange([createUsers])
    }
    // eslint-disable-next-line
  }, [createUsers])

  const userSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('firstName', firstName)
    form.append('lastName', lastName)
    form.append('password', password)
    form.append('email', email)
    if (picture_url_reference){
      form.append('picture_url', picture_url_reference, picture_url_reference.name)
    }else{
      toast.error('Adicione uma imagem')
    }

    if(email || firstName || lastName){
      createUser(form);
    }else{
      toast.error('Preencha todos os campos para continuar')
    }

    setLoader(false)
  }

  const createUser = async (form) => {
    await api.post('user', form, {
      headers: {'content-type': 'multipart/form-data'},
      onUploadProgress: () => {
        setLoader(true)
      }
    }).then(() => {
      setLoader(false)
      setCreateUsers(true)
    }
    ).catch(() => {
      setLoader(false)
    })
  }

  const handleImageChange = async (e) => {
    setPicture_url_reference(e.target.files[0])
    setPicture_url(URL.createObjectURL(e.target.files[0]))
  };

  return (
    <>
      <Container className="p-0 m-0">
        <Form onSubmit={userSubmit}>
          <Card className="card-user bg-transparent shadow-none m-0">
            <Row>
              <Col md="12">
                <div className="author mt-0">
                  <a href="#a" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar p-0 d-inline"
                      src={picture_url ?? default_avatar}
                      onClick={() => fileInputSend.current.click()}
                    />
                    <Row className="p-0">
                      <Col md="6">
                        <Label className="text-left text-dark">
                          primeiro Nome
                          <input required onChange={(e) => setFirstName(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Titulo"/>
                        </Label>
                      </Col>
                      <Col md="6">
                        <Label className="text-left text-dark">
                            Ultimo nome
                          <input required onChange={(e) => setLastName(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Descrição"/>
                        </Label>
                      </Col>
                    </Row>
                  </a>
                  <div className="text-center">
                    <input
                      type="file"
                      id="image"
                      accept="image/png, image/jpeg, image/gif, image/webp"
                      onChange={handleImageChange}
                      ref={fileInputSend}
                      hidden
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          <Card className="shadow-none bg-white">
            <CardBody className="bg-white p-0 mb-2">
              <Row>
                <Col md="12" className="w-full">
                  <Label className="text-left">
                    <span className="text-gray-800">Email</span>
                  </Label>
                  <input required onChange={(e) => setEmail(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="E-mail"/>
                </Col>
                <Col md="12" className="w-full">
                  <Label className="text-left">
                    <span className="text-gray-800">Senha</span>
                  </Label>
                  <input required onChange={(e) => setPassword(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="password" placeholder="senha"/>
                </Col>
              </Row>
              <Row className="text-right">
                <Col md="12">
                  {loader ?
                      <BeatLoader margin={10} color={'#3eda96'} loading={true} size={15} />
                    :
                      <Button className="btn btn-success m-0 mt-3" itemType="submit">
                        Confirmar
                      </Button>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
}
