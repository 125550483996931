/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";

import {
  Card,
  CardFooter,
  Container,
  Row,
  Col,
  Button,
  Label,
  CardBody,
} from "reactstrap";

// core components
import api from "../../../services/api";
import { BeatLoader } from "react-spinners";
import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import ToastNotify from "../../../utils/toasts";
import default_avatar from "../../../assets/img/default-avatar.png";
import { toast } from "react-toastify";
import Footer from "components/Footer/Footer";
import Tinymce from "components/RichText/Tinymce";

export default function Index() {

  const [name, setName] = React.useState('')
  const [icon, setIcon] = React.useState(null)
  const [description, setDescription] = React.useState(false)
  const [picture_url, setPicture_url] = React.useState(default_avatar)
  const [picture_url_reference, setPicture_url_reference] = React.useState(null)
  const fileInputSend = React.useRef(null);
  const [loader, setLoader] = React.useState(false)

  useEffect(() => {
    getDataCompany()
  }, [])

  const getDataCompany = () => {
    api.get('company').then((response) => {
      if(response.data !== ''){
        var iconData = process.env.REACT_APP_API_URL + '/' + response.data.icon
        setIcon(iconData)
        setPicture_url(iconData)
      }
      setName(response.data.name)
      setDescription(response.data.description)
    })
  }

  const companySubmit = async (e) => {
    e.preventDefault();
    const params = new FormData()
    params.append('name', name)
    params.append('description', description)
    if (picture_url_reference){
      params.append('icon', picture_url_reference, picture_url_reference.name)
    }else if (picture_url_reference === null && icon === null){
      toast.error('Adicione uma imagem')
    }
    if(picture_url_reference !== null || icon !== null){
      await api.post('company', params, {
          onUploadProgress: () => {
            setLoader(true)
          }
        }).then(() => {
          setLoader(false)
          toast.success('Dados atualizado com sucesso')
        }
      )
      setLoader(false)
      getDataCompany()
    }
  }

  const handleImageChange = async (e) => {
    try {
      setPicture_url_reference(e.target.files[0])
      setPicture_url(URL.createObjectURL(e.target.files[0]))

    } catch (error) {}
  };

  const resultTextArea = data => {
    setDescription(data)
  }

  return (
    <>
      <ToastNotify/>
      <AdminNavbar />
      <div className="wrapper">
        <div className="section">
          <Container>
            <Card className="card-user shadow-none p-3 mt-5">
              <div className="author">
                <a href="#a" onClick={(e) => e.preventDefault()}>
                  <img
                    alt="..."
                    className="avatar p-0 d-inline"
                    src={picture_url}
                    onClick={() => fileInputSend.current.click()}
                  />
                </a>
                <div className="text-center">
                  <input
                    type="file"
                    id="image"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                    ref={fileInputSend}
                    hidden
                  />
                </div>
              </div>
              <CardBody>
                <Row>
                  <Col md="12" className="text-left text-gray-50 text-md">
                    <Label tag={'span'} htmlFor="name">Nome</Label>
                    <input name="name" id="name" required onChange={(e) => setName(e.target.value)} defaultValue={name} className="mb-2 w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline mt-2" type="text" placeholder="Titulo"/>
                  </Col>
                  <Col md="12" className="text-left text-gray-50 text-md">
                    <Label tag={'span'}>
                      <h3 className="mb-2">Descrição</h3>
                    </Label>
                  </Col>
                  <Col md="12" className="text-left text-gray-50 text-md">
                    {/* {!loader && description && <Quill data={description} onChange={resultTextArea}/>} */}
                    {!loader && description && <Tinymce data={description} onChange={resultTextArea}/>}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="card-user shadow-none p-0 pt-3">
                {loader ?
                  <BeatLoader margin={20} color={'#3eda96'} loading={true} size={10} />
                :
                  <Button className="btn btn-success float-right" onClick={companySubmit}>Salvar</Button>
                }
              </CardFooter>
            </Card>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}
