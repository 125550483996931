import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../services/auth";

function PrivateRoute({
    component: Component, ...rest
  }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated() ? <Component {...props} /> : <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
        }
      />
    );
  }

export default PrivateRoute;
