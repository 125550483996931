/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";

import api from "../../../services/api";
import { BeatLoader } from "react-spinners";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Switch } from '@headlessui/react';

export default function CategoryCreate(props) {

  const [name, setName] = React.useState(props.category.name)
  const [icon, setIcon] = React.useState(props.category.icon)
  const [editCategories, setEditCategories] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [serverId, setServerId] = React.useState(props.category.serverId)
  const [enabled, setEnabled] = React.useState(props.category.status)
  const animatedComponents = makeAnimated();
  const servers = props.category.Servers

  useEffect(() => {
    if (props.onChange) {
      props.onChange([editCategories])
    }
    // eslint-disable-next-line
  }, [editCategories])

  const toggle = () => {
    if(enabled === 1) {
      setEnabled(0)
    }else{
      setEnabled(1)
    }
  }

  const categorySubmit = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams()
    params.append('name', name)
    params.append('icon', icon)
    params.append('serverId', serverId)
    params.append('status', enabled)
    await api.put(`category/${props.category.id}`, params, {
      onUploadProgress: () => {
        setLoader(true)
      }
    }).then(() => {
      setEditCategories(!editCategories)
      setLoader(false)
    }
    )
    setLoader(false)
  }

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      color: '#000',
    }),
  }

  return (
    <>
      <div className="author mt-0">
        <a href="#a" onClick={(e) => e.preventDefault()}>
          <Row className="p-0 items-center">
            <Col md="3">
              <Label className="text-left text-dark">
                Nome
              </Label>
              <input defaultValue={name} onChange={e => setName(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Nome"/>
            </Col>
            <Col md="3">
              <Label className="text-left text-dark">
                Icone
              </Label>
              <input defaultValue={icon} onChange={e => setIcon(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Icone"/>
            </Col>
            <Col md="3">
              <Label className="text-left">
                <span className="text-gray-800">Servidores</span>
              </Label>
              <Select
                defaultValue={props.category.Servers && props.category.Servers.filter((server) =>
                  server.value === props.category.serverId ?
                    { value: server.value, label: server.label }
                  :
                    ''
                )}
                placeholder="Servidores"
                onChange={e => setServerId(e.value)}
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={servers}
                styles={customStyles}
              />
            </Col>
            <Col md="3">
              <Label className="text-left">
                <span className="text-gray-800">Status</span>
              </Label>
              <Switch.Group>
                <div className="flex items-center">
                  <Switch
                    checked={enabled}
                    onChange={toggle}
                    className={`${
                      enabled === 1 ? 'bg-blue-600' : 'bg-gray-200'
                    } mr-2 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
                  >
                    <span
                      className={`${
                        enabled === 1 ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </Col>
          </Row>
        </a>
        {loader ?
            <BeatLoader margin={20} color={'#3eda96'} loading={true} size={10} />
          :
            <Button className="btn btn-success mt-3" onClick={categorySubmit}>Confirmar</Button>
        }
      </div>
    </>
  );
}
