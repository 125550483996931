import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/auth/LoginPage.js";
import Logout from "views/auth/Logout";
import ProfilePage from "views/examples/ProfilePage.js";
import PrivateRoute from "utils/privateRoutes";
import IndexAdmin from "views/admin/index";
import ProductList from "views/admin/products/index";
import CategoryList from "views/admin/categories/index";
import UserList from "views/admin/users/index";
import CouponList from "views/admin/coupons/index";
import InsertUpdates from "views/admin/updates/index";
import ServerList from "views/admin/servers/index";
import Company from "views/admin/company/index";
import Goal from "views/admin/goal/index";
import { Provider } from 'react-redux';
import store from 'Store/index';
import RoadMap from "views/examples/RoadMap";
import Updates from "views/examples/Updates";
import Voting from "views/examples/Voting";
import AdsTerms from "views/examples/AdsTerms";

const  Routes = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/home" render={(props) => <Index {...props} />} />

          <Route path="/login" render={(props) => <LoginPage {...props} />} />
          <PrivateRoute path="/logout" component={Logout} />

          <Route path="/server-page" component={(props) => <LandingPage {...props} />} />
          <Route path="/roadmap" component={(props) => <RoadMap {...props} />} />
          <Route path="/adsterms" component={(props) => <AdsTerms {...props} />} />
          <Route path="/voting" component={(props) => <Voting {...props} />} />
          <Route path="/updates" component={(props) => <Updates {...props} />} />
          <PrivateRoute path="/profile-page" component={(props) => <ProfilePage {...props} />} />
          <PrivateRoute path="/index-admin" component={(props) => <IndexAdmin {...props} />} />
          <PrivateRoute path="/servers" component={(props) => <ServerList {...props} />} />
          <PrivateRoute path="/company" component={(props) => <Company {...props} />} />
          <PrivateRoute path="/categories" component={(props) => <CategoryList {...props} />} />
          <PrivateRoute path="/products" component={(props) => <ProductList {...props} />} />
          <PrivateRoute path="/users" component={(props) => <UserList {...props} />} />
          <PrivateRoute path="/cupons" component={(props) => <CouponList {...props} />} />
          <PrivateRoute path="/insert-updates" component={(props) => <InsertUpdates {...props} />} />
          <PrivateRoute path="/goal" component={(props) => <Goal {...props} />} />
          <Redirect from="*" to="/home" />
        </Switch>
      </BrowserRouter>
    </Provider>
  )
}
export default Routes;
