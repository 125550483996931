import { useEffect } from 'react'
import { useState } from 'react'
import { Label } from 'reactstrap'
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Switch } from '@headlessui/react';

const DinamicInputs = (props) => {
  const animatedComponents = makeAnimated();
  const [inputList, setInputList] = useState(props.defaultValues ? props.defaultValues.map((item) => {return {id: item.id, command: item.command, serverId: item.serverId, isOnline: item.is_online}}) : [{command: null}])
  const servers = props.servers

  useEffect(() => {
    if (props.onChange){
      props.onChange(inputList)
    }
    // eslint-disable-next-line
  }, [inputList])

  const handleAdd = () => {
    setInputList([...inputList, {command: null}])
  }

  const handleRemove = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }

  const handleInputChange = (e, index) => {
    const list = [...inputList]
    if (typeof e.target !== 'undefined'){
      list[index]['command'] = e.target.value
    }else if (typeof e.value !== 'undefined'){
      list[index]['serverId'] = e.value
    }else if (typeof e.isOnline !== 'undefined'){
      list[index]['isOnline'] = e.isOnline
    }
    setInputList(list)
  }

  const defaultServerSelect = (value) => {
    const server = servers.filter((server) => server.id === value.serverId)[0]
      if (server){
        return { value: server.id, label: server.name }
      }
      return ''
  }

  return (
    <>
      {inputList.map((value, index) => {
        return (
          <div key={index} className="pt-2">
            <div className="grid grid-cols-12 gap-1 items-center">
              <div className='col-span-3'>
                <Label className="text-left text-gray-800" >
                  <span className="text-gray-800">Servidor</span>
                </Label>
                {}
                {servers && <Select
                  defaultValue={defaultServerSelect(value)}
                  placeholder="Servidor"
                  onChange={(e) => handleInputChange(e, index)}
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  options={servers.map((server) => { return {value: server.id, label: server.name}})}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      color: '#000',
                    }),
                    container: provided => ({ ...provided, zIndex: 999 - (index * 10) }),
                  }}
                />}
              </div>
              <div className='col-span-7'>
                <Label className="text-left">
                  <span className="text-gray-800">Comando</span>
                </Label>
                <input defaultValue={value.command} onChange={(e) => handleInputChange(e, index)} className="text-gray-800 w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="give {player} minecraft:diamond_pickaxe 1"/>
              </div>
              <div className='col-span-1'>
                <Label className="text-left">
                  <span className="text-gray-800">Online</span>
                </Label>
                <Switch.Group>
                  <div className="flex items-center">
                    <Switch
                      checked={value.isOnline}
                      onChange={(e) =>
                        handleInputChange({isOnline: e}, index)
                      }
                      className={`${
                        value.isOnline ? 'bg-blue-600' : 'bg-gray-200'
                      } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    >
                      <span
                        className={`${
                          value.isOnline ? 'translate-x-6' : 'translate-x-1'
                        } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                      />
                    </Switch>
                  </div>
                </Switch.Group>
              </div>
              <div className='col-span-1 text-center pt-6'>
                {inputList.length -1 === index &&
                  <span>
                    <i className=" text-green-100 fas fa-plus fa-lg hover:bg-green-900 transform transition delay-100 hover:scale-125 bg-green-500 rounded-full pt-2.5 pb-2.5 pl-1 pr-1 mr-2" onClick={handleAdd}></i>
                  </span>
                }
                {inputList.length !== 1 &&
                  <span>
                    <i className=" text-red-100 fas fa-times fa-lg hover:bg-red-900 transform transition delay-100 hover:scale-125 bg-red-500 rounded-full pt-3 pb-2.5 pl-1.5 pr-1.5" onClick={() => handleRemove(index)}></i>
                  </span>
                }
              </div>
            </div>
          </div>)
      })
      }
      {inputList.length === 0 &&
        <span>
          <i className="float-right text-green-100 fas fa-plus fa-lg hover:bg-green-900 transform transition delay-100 hover:scale-125 bg-green-500 rounded-full pt-2.5 pb-2.5 pl-1 pr-1 mr-8 mt-10" onClick={handleAdd}></i>
        </span>
      }
    </>

  )
}

export default DinamicInputs
