import { useRef } from 'react';
 import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { useEffect } from 'react';
import api from 'services/api';

const Tinymce = (props) => {
  const [valueResult, setValueResult] = useState(props.data ?? 'Edite seu conteúdo aqui!');
  // eslint-disable-next-line
  const [defaultvalue, setDefaultValue] = useState(props.data ?? 'Edite seu conteúdo aqui!');
  const editorRef = useRef(null);

  useEffect(() => {
    if(props.onChange){
      props.onChange(valueResult)
    }
    // eslint-disable-next-line
  }, [valueResult])

  const uploadHandler = async (blobInfo) => {
    const formData = new FormData();
    formData.append('image', blobInfo.blob(), blobInfo.filename());
    const res = await api.post('/images/upload', formData);
    return res?.data?.url;
  }

  const handleCallback = (editor) => {
    editor.on("keydown", function(e){
      if ((e.keyCode == 8 || e.keyCode == 46) && editor.selection) {
         var selectedNode = editor.selection.getNode();
         if (selectedNode && selectedNode.nodeName == 'IMG') {
            var imageSrc = selectedNode.src.split('/')[3];
            api.delete('images/remove/'+ imageSrc)
         }

       }
    });
  }

  const handleEditorChange = (e) => {
    const { value } = e.target;
    if (value !== ''){
      setValueResult(value)
    }
  }

  const parseEditorData = (content, editor) => {
    const { targetElm } = editor;
    const { name } = targetElm;
    return {
      target: {
        name,
        value: content
      }
    };
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_API_KEY_TINYMCE}
      onInit={(evt, editor) => editorRef.current = editor}
      initialValue={defaultvalue}
      init={{
        autosave_restore_when_empty: false,
        autosave_retention: '2m',
        autosave_ask_before_unload: true,
        autosave_interval: '30s',
        toolbar_mode: 'sliding',
        height: 500,
        menubar: 'file edit view insert format tools table help',
        plugins: [
          'hr',
          'autosave',
          'quickbars',
          'advlist',
          'autolink',
          'link',
          'image',
          'lists',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'media',
          'table',
          'wordcount',
          'code',
          'codesample',
          'emoticons',
          'fullscreen',
          'insertdatetime',
          'autoresize',
          'textcolor'
        ],
        language: 'pt_BR',
        init_instance_callback: handleCallback,
        images_upload_handler: uploadHandler,
        toolbar: 'insertfile undo redo | styleselect | bold italic hr forecolor backcolor | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | code codesample | charmap emoticons fullscreen insertdatetime preview anchor searchreplace visualblocks | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
      }}
      onEditorChange={(content, editor) => handleEditorChange(parseEditorData(content, editor))}
    />
  );
}
export default Tinymce
