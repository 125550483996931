/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, Fragment, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap";

import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import api from "../../../services/api";
import ServerCreate from "./create";
import ServerEdit from "./edit";
import { Dialog, Transition } from '@headlessui/react'
import { toast } from "react-toastify";
import ToastNotify from "../../../utils/toasts";
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import '../../../assets/css/sortable.css'

export default function ServerList() {
  const [servers, setServers] = React.useState([]);
  const [serverEdit, setServerEdit] = React.useState({});
  const [removeServer, setRemoveServer] = React.useState({});
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [isOpenRemove, setIsOpenRemove] = useState(false)

  useEffect(() => {
    loadList()
  }, [])

  const EditEventHandler = data => {
    if(data[0]){
      closeModalEdit()
      loadList()
    }
  };

  const CreateEventHandler = data => {
    if(data[0]){
      closeModal()
      loadList()
    }
  };

  const loadList = async () => {
    await api.get('server-list').then(
       response => {
         setServers(response.data)
      }
    )
  }

  const serverRemove = async (e) => {
    e.preventDefault();
    await api.delete(`server/${removeServer.id}`)
    .then(() => {
      toast.success("Removido com sucesso");
      loadList()
    })
    setIsOpenRemove(false)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModalEdit() {
    setIsOpenEdit(false)
  }

  function openModalEdit() {
    setIsOpenEdit(true)
  }

  function openModalRemove() {
    setIsOpenRemove(true)
  }

  function closeModalRemove() {
    setIsOpenRemove(false)
  }

  const DragHandle = sortableHandle(() => <span><i className="fa fa-bars" ></i></span>)

  const SortableItem = sortableElement(({server}) => (
    <tr key={server.id}>
      <td className="sortable-thumb"><DragHandle /></td>
      <td>{server.name}</td>
      <td><div className="no-tailwindcss-base" dangerouslySetInnerHTML={{__html: server.icon }} /></td>
      <td width="100" className="text-center">
        <button onClick={() => {
            openModalEdit()
            setServerEdit(server)
          }}
          className="mr-2"
        >
          <i className="text-gray-50 fas fa-edit fa-lg"></i>
        </button>
        <button onClick={() => {
            openModalRemove()
            setRemoveServer(server)
          }}
          className="ml-2"
        >
          <i className="text-red-500 fas fa-times fa-lg"></i>
        </button>
      </td>
    </tr>
  ));

  const SortableContainer = sortableContainer(({children}) => {
    return <div
      style={{
        maxHeight: '600px',
        overflowY: 'auto'
      }}
    >
      <Table height="600">
        <thead className="text-primary">
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Icone</th>
            <th className="text-center">Configurações</th>
          </tr>
        </thead>
        <tbody>
          {children}
        </tbody>
      </Table>
    </div>
  });

  const onSortEnd = ({oldIndex, newIndex}) => {
    redefinePositionServer(arrayMove(servers, oldIndex, newIndex))
  }

  const redefinePositionServer = (movedServerList) => {
    let newServers = []
    movedServerList.forEach((server, index) => newServers.push({...server, position: index}))
    api.put('servers/many', newServers)
    setServers(movedServerList)
  }
  return (
    <>
      <ToastNotify/>
      <AdminNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
          <Container>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Row className="align-items-center">
                      <Col md="9" className="text-left">
                        <CardTitle className="text-lg">Servidores</CardTitle>
                      </Col>
                      <Col md="3" className="text-right">
                        <Button
                          className="btn btn-success"
                          type={"button"}
                          onClick={
                            () => openModal()
                          }
                        >
                        Cadastrar novo servidor
                      </Button>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <SortableContainer helperClass="sortableHelper" onSortEnd={onSortEnd} useDragHandle>
                      {servers.map((value, index) => (
                        <SortableItem key={`item-${value.id}`} index={index} server={value} />
                      ))}
                    </SortableContainer>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-10 overflow-y-auto"
              onClose={closeModal}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-200"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block w-full max-w-2xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-lg font-medium leading-6 text-gray-900"
                    >
                      Criar servidor
                      <button type="button" onClick={closeModal}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </Dialog.Title>
                      <ServerCreate position={servers.length} onChange={CreateEventHandler}/>
                  </div>

                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
          <Transition appear show={isOpenEdit} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-10 overflow-y-auto"
              onClose={closeModalEdit}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block w-full max-w-2xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-lg font-medium leading-6 text-gray-900"
                    >
                      Editar servidor
                      <button type="button" onClick={closeModalEdit}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </Dialog.Title>
                    <ServerEdit onChange={EditEventHandler} server={serverEdit}/>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
          <Transition appear show={isOpenRemove} as={Fragment}>
            <Dialog
              as="div"
              className="fixed inset-0 z-10 overflow-y-auto"
              onClose={closeModalRemove}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                    <Dialog.Title
                      as="h3"
                      className="mb-3 text-lg font-medium leading-6 text-gray-900"
                    >
                      Remover servidor
                      <button type="button" onClick={closeModalRemove}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Tem certeza que deseja remover o servidor {removeServer.name}?
                      </p>
                    </div>
                    <div className="d-flex">

                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-green-500 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                          onClick={closeModalRemove}
                        >
                          Cancelar
                        </button>
                      </div>
                      <div className="mt-4">
                        <button
                          type="button"
                          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                          onClick={(e) => {serverRemove(e)}}
                        >
                          Remover
                        </button>
                    </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    </div>

    </>
  );
}
