/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import iconDiscord from '../../assets/Logos/clyde/icon_clyde_blurple_RGB.webp'

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="3">
            <h1 className="title">{process.env.REACT_APP_WEBSITE_NAME}</h1>
          </Col>
          <Col md="3">
            <Nav>
              <NavItem>
                <NavLink to="/" tag={Link}>
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/server-page" tag={Link}>
                  Landing
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="3">
            <Nav>
              <NavItem>
                <NavLink href="https://discord.com/invite/Rurk6GRQYk">
                  Entre em contato conosco
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://discord.com/invite/Rurk6GRQYk">
                  Sobre nós
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col md="3">
            <h3 className="title">Siga-nos:</h3>
            <div className="btn-wrapper profile">
              <Button
                className="btn-icon btn-neutral btn-round btn-simple"
                color="default"
                href="https://discord.com/invite/Rurk6GRQYk"
                id="tooltip318450378"
                target="_blank"
              >
                <img src={iconDiscord} alt="discord icon" width="50%" height="50%" />
              </Button>
              <UncontrolledTooltip delay={0} target="tooltip318450378">
                Entre em nosso servidor
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
