import { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";
import api from "../../../services/api";
import { BeatLoader } from "react-spinners";
import default_avatar from "../../../assets/img/default-avatar.png";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Switch } from '@headlessui/react';
import Tinymce from "components/RichText/Tinymce";
import DinamicInputs from "./Components/DinamicInputs";


export default function ProductEdit(props) {
  const animatedComponents = makeAnimated();
  const [title, setTitle] = useState(props.product.title)
  const [description, setDescription] = useState(props.product.description)
  const [picture_url, setPicture_url] = useState(process.env.REACT_APP_API_URL + '/' + props.product.picture_url)
  const [picture_url_reference, setPicture_url_reference] = useState(null)
  const [currency_id, setCurrency_id] = useState(props.product.currency_id)
  const [price, setPrice] = useState(props.product.price)
  const [unit_price, setUnit_price] = useState(props.product.unit_price)
  const [quantity, setQuantity] = useState(props.product.quantity)
  const [commands, setCommands] = useState(props.product.command)
  const [categoryId, setCategoryId] = useState(props.product.categoryId)
  const [enabled, setEnabled] = useState(props.product.quantity_select === 2 ? true : false)
  const [enabledStatus, setEnabledStatus] = useState(props.product.status)
  const [loader, setLoader] = useState(false)
  const categories = props.product.listCategories
  const fileInputSend = useRef(null);
  const [editProducts, setEditProducts] = useState(false)
  const toggle = () => {
    setEnabled(!enabled)
  }

  const toggleStatus = () => {
    if(enabledStatus === 1) {
      setEnabledStatus(0)
    }else{
      setEnabledStatus(1)
    }
  }


  useEffect(() => {
    if(props.onChange){
      props.onChange([editProducts])
    }

    // eslint-disable-next-line
  }, [editProducts])

  const productSubmit = async () => {
    const form = new FormData();
    setUnit_price((parseFloat(price.replace(',', '.')) / quantity).toFixed(2))
    form.append('title', title)
    form.append('description', description)
    if (picture_url_reference){
      form.append('picture_url', picture_url_reference, picture_url_reference.name)
    }else{
      form.delete('picture_url')
    }
    form.append('currency_id', currency_id.value ?? currency_id)
    form.append('price', price)
    form.append('unit_price', (parseFloat(price.replace(',', '.')) / quantity).toFixed(2) ?? unit_price)
    form.append('quantity', quantity)
    form.append('commands', JSON.stringify(commands))
    form.append('categoryId', categoryId.value ?? categoryId)
    if(!enabled){
      form.append('quantity_select', parseInt(0))
    }else{
      form.append('quantity_select', parseInt(2))
    }
    form.append('status', enabledStatus)
    updateProduct(form)
    setLoader(false)
  }

  const updateProduct = async (form) => {
    await api.put(`product/${props.product.id}`, form, {
      headers: {'content-type': 'multipart/form-data'},
      onUploadProgress: () => {
        setLoader(true)
      }
    }).then(() => {
      setLoader(false)
      setEditProducts(true)
    }).catch(() => {
      setLoader(false)
    })
    setEditProducts(true)
  }

  const handleImageChange = async (e) => {
    setPicture_url_reference(e.target.files[0])
    setPicture_url(URL.createObjectURL(e.target.files[0]))
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      color: '#000',
    }),
    container: provided => ({ ...provided, zIndex: 9999 }),
  }

  const currency = [
    { value: "BRL", label: "BRL" },
  ]

  const resultTextArea = data => {
    setDescription(data)
  }

  const commandsEventHandler = data => {
    setCommands(data)
  }

  return (
    <>
      <Container className="p-0 m-0">
          <Card className="card-user bg-transparent shadow-none m-0">
            <Row>
              <Col md="12">
                <div className="author mt-0">
                  <a href="#a" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar p-0 d-inline"
                      src={picture_url ?? default_avatar}
                      onClick={() => fileInputSend.current.click()}
                    />
                  </a>
                  <div className="text-center">
                    <input
                      type="file"
                      id="image"
                      accept="image/png, image/jpeg, image/gif, image/webp"
                      onChange={handleImageChange}
                      ref={fileInputSend}
                      hidden
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
          <Card className="shadow-none bg-white">
            <CardBody className="bg-white p-0 mb-2">
              <Row>
                <Col md="12">
                  <Label className="text-left text-dark">
                    Titulo
                  </Label>
                  <input defaultValue={props.product.title} onChange={(e) => setTitle(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Title"/>
                </Col>
                <Col md="3">
                  <Label className="text-left text-gray-800" >
                      <span className="text-gray-800">Moeda</span>
                  </Label>
                  <Select
                    defaultValue={currency && currency.filter((currency) => {
                      return currency.value === props.product.currency_id ?
                        { value: currency.value, label: currency.label }
                      :
                        ''
                    })}
                    placeholder="Moeda"
                    onChange={e => setCurrency_id(e)}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    options={currency}
                    styles={customStyles}
                  ></Select>
                </Col>
                <Col md="3">
                  <Label className="text-left">
                    <span className="text-gray-800">Categoria</span>
                  </Label>
                  <Select
                    defaultValue={categories && categories.filter((category) => {
                      return category.value === props.product.categoryId ?
                        { value: category.value, label: category.label }
                      :
                        ''
                    })}
                    placeholder="Categoria"
                    onChange={e => setCategoryId(e)}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    options={categories}
                    styles={customStyles}
                  ></Select>
                </Col>
                <Col md="3">
                  <Label className="text-left">
                    <span className="text-gray-800">Valor</span>
                  </Label>
                  <input defaultValue={props.product.price} onChange={(e) => setPrice(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Preço"/>
                </Col>
                <Col md="3">
                  <Label className="text-left">
                    <span className="text-gray-800">Quantidade</span>
                  </Label>
                  <input type="number" defaultValue={props.product.quantity} onChange={(e) => setQuantity(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" placeholder="Quantidade"/>
                </Col>
                <Col md="12" className="text-left ">
                  <Label className="text-dark">
                    Descrição
                  </Label>
                  {props.product.description && <Tinymce data={props.product.description} onChange={resultTextArea}/>}
                </Col>
                <Col md="12">
                  <DinamicInputs servers={props.servers} onChange={commandsEventHandler} defaultValues={props.product.Commands}/>
                </Col>
                <Col md="2">
                  <Label className="text-left">
                    <span className="text-gray-800">Alterar quantidade</span>
                  </Label>
                    <Switch.Group>
                    <div className="flex items-center">
                      <Switch
                        checked={enabled}
                        onChange={toggle}
                        className={`${
                          enabled ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
                      >
                        <span
                          className={`${
                            enabled ? 'translate-x-6' : 'translate-x-1'
                          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                </Col>
                <Col md="2">
                  <Label className="text-left">
                    <span className="text-gray-800">Status</span>
                  </Label>
                  <Switch.Group>
                    <div className="flex items-center">
                      <Switch
                        checked={enabledStatus}
                        onChange={toggleStatus}
                        className={`${
                          enabledStatus === 1 ? 'bg-blue-600' : 'bg-gray-200'
                        } mr-2 relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
                      >
                        <span
                          className={`${
                            enabledStatus === 1 ? 'translate-x-6' : 'translate-x-1'
                          } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                        />
                      </Switch>
                    </div>
                  </Switch.Group>
                </Col>
              </Row>
              <Row className="text-right">
                <Col md="12">
                  {loader ?
                      <BeatLoader margin={10} color={'#3eda96'} loading={true} size={15} />
                    :
                      <Button onClick={productSubmit} className="btn btn-success m-0" itemType="submit">Confirmar</Button>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
      </Container>
    </>
  )
}
