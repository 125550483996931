import "./styles.css";

import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";

// CALL IT ONCE IN YOUR APP
if (typeof window !== "undefined") {
  injectStyle();
}

function ToastNotify(){
  return (
    <>
        <ToastContainer
            position={"top-right"}
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            progress={undefined}
            closeButton={false}
            // newestOnTop={false}
            rtl={false}
        />
    </>
  )
}
export default ToastNotify;
