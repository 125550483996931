/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ReactDOM from "react-dom";

import "assets/css/blk-design-system-react.min.css";
import "assets/css/tailwind.min.css";
import "assets/fontawesome/css/all.min.css";

import "assets/css/nucleo-icons.min.css";

import "assets/demo/demo.min.css";

import "assets/mineIcons/icons-minecraft-0.5.min.css";
import "assets/mineIcons/icons-minecraft-0.5.webp";
import "assets/mineIcons/minecraft-block-and-entity.json";

import App from "./App";

ReactDOM.render(
  <App/>,
  document.getElementById("root")
);
