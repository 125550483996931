/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap";

import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import api from "../../../services/api";
import UserCreate from "./create";
import UserEdit from "./edit";
import { toast } from "react-toastify";
import ToastNotify from "../../../utils/toasts";
import { Dialog, Transition } from '@headlessui/react'


export default function UserList() {
  const [users, setUsers] = React.useState([]);
  const [userEdit, setUserEdit] = React.useState({});
  const [removeUser, setRemoveUser] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpenEdit, setIsOpenEdit] = React.useState(false)
  const [isOpenRemove, setIsOpenRemove] = React.useState(false)

  useEffect(() => {
    loadList()
  }, [])

  const loadList = async () => {
    await api.get('users').then(
       response => {
         setUsers(response.data)
      }
    )
  }

  const userRemove = async (e) => {
    e.preventDefault();
    await api.delete(`user/${removeUser.id}`)
    .then(() => {
      toast.success("Removido com sucesso");
      loadList()
    })
    setIsOpenRemove(false)
  }

  const EditEventHandler = data => {
    if(data[0]){
      loadList()
    }
  };
  const CreateEventHandler = data => {
    if(data[0]){
      loadList()
    }
  };

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  function closeModalEdit() {
    setIsOpenEdit(false)
  }

  function openModalEdit() {
    setIsOpenEdit(true)
  }

  function openModalRemove() {
    setIsOpenRemove(true)
  }
  function closeModalRemove() {
    setIsOpenRemove(false)
  }

  const editButton = (product) => {
    setTimeout(() => {
      openModalEdit()
    }, 500)
    setUserEdit(product)
  }

  return (
    <>
      <ToastNotify/>
      <AdminNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col md="9" className="text-left">
                          <CardTitle tag="h1">Usuários</CardTitle>
                        </Col>
                        <Col md="3" className="text-right">
                          <Button
                            className="btn btn-success"
                            type={"button"}
                            onClick={() =>openModal()}
                          >
                          Cadastrar novo usuário
                        </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-0">
                      <div
                        style={{
                          maxHeight: '600px',
                          overflowY: 'auto'
                        }}
                      >
                        <Table height="600">
                          <thead className="text-primary">
                            <tr>
                              <th>#</th>
                              <th>Primeiro nome</th>
                              <th>Ultimo nome</th>
                              <th>Email</th>
                              {/* <th className="text-center">Imagem</th> */}
                              <th className="text-center">Configurações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {users.map((user) => (
                              <tr key={user.id}>
                                <td>{user.id}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td width="100" className="text-center">
                                  <img className="m-0 d-inline"  src={process.env.REACT_APP_API_URL + '/' + user.picture_url} alt="..." width="50%"/>
                                </td>
                                <td width="100" className="text-center">
                                  <button onClick={() => {
                                    editButton(user)
                                  }}
                                  >
                                    <i className="fas fa-edit"></i>
                                  </button>
                                  <button onClick={() => {
                                      openModalRemove()
                                      setRemoveUser(user)
                                    }}
                                    className="ml-2"
                                  >
                                    <i className="text-red-500 fas fa-times fa-lg"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            <Transition appear show={isOpen} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
              >
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0" />
                  </Transition.Child>

                  {/* This element is to trick the browser into centering the modal contents. */}
                  <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="mb-3 text-lg font-medium leading-6 text-gray-900"
                      >
                        Criar usuário
                        <button type="button" onClick={closeModal}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                      </Dialog.Title>
                      <UserCreate onChange={CreateEventHandler}/>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={isOpenEdit} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModalEdit}
              >
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0" />
                  </Transition.Child>

                  <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="mb-3 text-lg font-medium leading-6 text-gray-900"
                      >
                        Editar usuário
                        <button type="button" onClick={closeModalEdit}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                      </Dialog.Title>
                      <UserEdit onChange={EditEventHandler} user={userEdit}/>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
            <Transition appear show={isOpenRemove} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModalRemove}
              >
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0" />
                  </Transition.Child>

                  <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="mb-3 text-lg font-medium leading-6 text-gray-900"
                      >
                        Remover usuário
                        <button type="button" onClick={closeModalRemove}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Tem certeza que deseja remover a usuário {removeUser.firstName}?
                        </p>
                      </div>
                      <div className="d-flex">

                        <div className="mt-4">
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-green-500 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={closeModalRemove}
                          >
                            Cancelar
                          </button>
                        </div>
                        <div className="mt-4">
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={(e) => {userRemove(e)}}
                          >
                            Remover
                          </button>
                      </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
          </div>
        </div>
      </div>
    </>
  );
}
