/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Fragment, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Table,
  Button,
} from "reactstrap";

import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import api from "../../../services/api";
import ProductCreate from "./create";
import ProductEdit from "./edit";
import { toast } from "react-toastify";
import ToastNotify from "../../../utils/toasts";
import { Dialog, Transition } from '@headlessui/react'
import { sortableContainer, sortableElement, sortableHandle, arrayMove } from 'react-sortable-hoc';
import '../../../assets/css/sortable.css'
import { money } from "utils/functions";

export default function ProductList() {

  const [products, setProducts] = React.useState([]);
  const [productEdit, setProductEdit] = React.useState({});
  const [loader, setLoader] = React.useState(false);
  const [removeProduct, setRemoveProduct] = React.useState({});
  const [isOpen, setIsOpen] = React.useState(false)
  const [isOpenEdit, setIsOpenEdit] = React.useState(false)
  const [isOpenRemove, setIsOpenRemove] = React.useState(false)
  const [servers, setServers] = React.useState(false)

  useEffect(() => {
    loadList()
    getServers()

  }, [])

  const loadList = async () => {
    setLoader(true)
    await api.get('product-list').then((response) => {
      setProducts(response.data)
      setLoader(false)
    })
  }

  const getServers = async () => {
    await api.get('server-list').then((response) => {
      setServers(response.data)
    })
  }


  const productRemove = async (e) => {
    e.preventDefault()
    await api.delete(`product/${removeProduct.id}`)
    .then(() => {
      toast.success("Removido com sucesso");
      loadList()
    })
    setIsOpenRemove(false)
  }

  const editEventHandler = async (data) => {
    if(data[0]){
      closeModalEdit()
      loadList()
    }
  }

  const createEventHandler = async (data) => {
    if(data[0]){
      closeModal()
      loadList()
    }
  }

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  function closeModalEdit() {
    setIsOpenEdit(false)
  }

  function openModalEdit() {
    setIsOpenEdit(true)
  }

  function openModalRemove() {
    setIsOpenRemove(true)
  }

  function closeModalRemove() {
    setIsOpenRemove(false)
  }

  const editButton = async (product) => {
    await api.get('category-list').then((response) => {
      product.listCategories = response.data.map((value) => ({ value: value.id, label: value.Server.name + ' -> ' + value.name }))
    })
    setProductEdit(product)
    openModalEdit()
  }


  const onSortEnd = ({oldIndex, newIndex}) => {
    redefinePositionProduct(arrayMove(products, oldIndex, newIndex))
  }

  const redefinePositionProduct = (movedProductList) => {
    let newProducts = []
    movedProductList.forEach((Products, index) => newProducts.push({...Products, position: index}))
    api.put('/products/many', newProducts)
    setProducts(movedProductList)
  }


  return (
    <>
      <ToastNotify/>
      <AdminNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row className="align-items-center">
                        <Col md="9" className="text-left">
                          <CardTitle className="text-lg">Produtos</CardTitle>
                        </Col>
                        <Col md="3" className="text-right">
                          <Button
                            className="btn btn-success"
                            type={"button"}
                            onClick={() =>openModal()}
                          >
                          Cadastrar novo produto
                        </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {!loader &&
                        <SortableContainer openModalRemove={openModalRemove} setRemoveProduct={setRemoveProduct} editButton={editButton} items={products} helperClass="sortableHelper" onSortEnd={onSortEnd} useDragHandle />
                      }
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="z-50 inline-block w-full max-w-5xl p-6 my-20 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="mb-3 text-lg font-medium leading-6 text-gray-900"
                >
                  Criar produto
                  <button type="button" onClick={closeModal}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                </Dialog.Title>
                <ProductCreate servers={servers} position={products.length} onChange={createEventHandler}/>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpenEdit} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModalEdit}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-5xl p-6 my-20 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="mb-3 text-lg font-medium leading-6 text-gray-900"
                >
                  Editar produto
                  <button type="button" onClick={closeModalEdit}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                </Dialog.Title>
                <ProductEdit servers={servers} onChange={editEventHandler} product={productEdit}/>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpenRemove} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModalRemove}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-20 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="mb-3 text-lg font-medium leading-6 text-gray-900"
                >
                  Remover produto
                  <button type="button" onClick={closeModalRemove}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Tem certeza que deseja remover o produto {removeProduct.title}?
                  </p>
                </div>
                <div className="d-flex">

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-green-500 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={closeModalRemove}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                      onClick={(e) => {productRemove(e)}}
                    >
                      Remover
                    </button>
                </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

const DragHandle = sortableHandle(() => <span><i className="fa fa-bars fa-2x" ></i></span>)

const SortableContainer = sortableContainer(({openModalRemove, setRemoveProduct, editButton, items}) => {
  return <div
    style={{
      maxHeight: '600px',
      overflowY: 'auto'
    }}
  >
    <Table height="600">
      <thead className="text-primary">
        <tr>
          <th>#</th>
          <th>Nome</th>
          <th>Moeda</th>
          <th>Valor</th>
          <th>Servidor {'->'} Categoria</th>
          <th className="text-center">Imagem</th>
          <th className="text-center">Configurações</th>
        </tr>
      </thead>
      <tbody>
        {items.map((value, index) => {
            return <SortableItem openModalRemove={openModalRemove} setRemoveProduct={setRemoveProduct} editButton={editButton} pressDelay={500} distance={1} currentIndex={index} key={`item-${value.id}`} index={index} product={value} />
          }
        )}
      </tbody>
    </Table>
  </div>
});

const SortableItem = sortableElement(({openModalRemove, setRemoveProduct, editButton, product}) => {
  return <tr key={product.id}>
    <td className="sortable-thumb"><DragHandle /></td>
    <td>{product.title}</td>
    <td>{product.currency_id}</td>
    <td>{money.format(product.price)}</td>
    <td>{product.Category.Server.name} {'->'} {product.Category.name}</td>
    <td width="100" className="text-center">
      <img className="m-0 d-inline" src={process.env.REACT_APP_API_URL + '/' + product.picture_url} alt="..." width="50%"/>
      </td>
    <td width="100" className="text-center">
      <button onClick={() => editButton(product)}><i className="fas fa-edit fa-lg"></i></button>
      <button onClick={() => {
          openModalRemove()
          setRemoveProduct(product)
        }}
        className="ml-2"
      >
        <i className="text-red-500 fas fa-times fa-lg"></i>
      </button>
    </td>
  </tr>
});
