/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

import {
  Row,
  Col,
  Container,
  CardBody,
  CardHeader,
  Card,
  Label,
  CardTitle,
} from "reactstrap";

import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import api from "../../services/api";
import ReCAPTCHA from "react-google-recaptcha";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";


export default function Voting() {
  const [description, setDescription] = useState(false)
  const [stars, setStars] = useState('')
  const [tokenCaptcha, setTokenCaptcha] = useState('')
  const [mineUser, setMineUser] = useState('')
  const [checkbox, setCheckBox] = useState(false)
  const [message, setMessage] = useState('')
  const [voteList, setVoteList] = useState([])
  const recaptchaRef = React.createRef();

  React.useEffect(() => {
    document.body.classList.toggle("landing-page")
    votelist()
    return function cleanup() {
      document.body.classList.toggle("landing-page")
    };
  },[]);

  const starsConfig = {
    size: 24,
    count: 5,
    color: "#a8a8a8",
    activeColor: "#ffd700",
    value: 0,
    a11y: true,
    isHalf: true,
    class: "text-center",
    className: "text-center",
    emptyIcon: <i className="far fa-star" />,
    halfIcon: <i className="fa fa-star-half-alt" />,
    filledIcon: <i className="fa fa-star" />,
    onChange: newValue => {
      setStars(newValue)
    }
  };

  const votelist = () => {
    api.get('vote').then((response) => setVoteList(response.data))
  }

  const validateAndSendData = () => {
    if (tokenCaptcha) {
      const form = new URLSearchParams()
      form.append('stars', stars)
      form.append('mineUser', mineUser)
      form.append('tokenCaptcha', tokenCaptcha)
      form.append('checkbox', checkbox)
      form.append('description', description)
      if (!stars){
        toast.error('De uma nota para continuar')
      }
      if (!mineUser){
        toast.error('Insira o nome de usuário')
      }
      if (checkbox){
        if (!description){
          setDescription('')
        }
        if (mineUser && stars && description){
          api.post('vote', form).then(() => {
            recaptchaRef.current.reset()
            if (checkbox){
              toast.success('Votação e avaliação concluída')
            }else{
              toast.success('Votação concluída')
            }
            votelist()
          }).catch((err) => {
            recaptchaRef.current.reset()
            if (err.response.status === 500){
              toast.error('Erro ao votar!')
            }else if (err.response.status === 401){
              setMessage(err.response.data.message)
              toast.error('Você já votou, volte mais tarde!')
            }
          })
        }
      }else{
        if (mineUser && stars){
          api.post('vote', form).then(() => {
            recaptchaRef.current.reset()
            if (checkbox){
              toast.success('Votação e avaliação concluída!')
            }else{
              toast.success('Votação concluída!')
            }
            votelist()
          }).catch((error) => {
            recaptchaRef.current.reset()
            if (error.response.status === 500){
              toast.error('Erro ao votar!')
            }else if (error.response.status === 401){
              setMessage(error.response.data.message)
              toast.error('Você já votou, volte mais tarde!')
            }
          })
        }
      }
    }else{
      toast.error('Refaça o captcha')
      recaptchaRef.current.reset()
    }
  }

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section mt-44 mb-20">
          <img alt="path3" className="path" src={require("assets/img/path4.png").default}
          />
          <Container>
              <Card className="bg-white">
                <CardHeader>
                  <h2 className="text-dark text-lg">Votar para nosso servidor</h2>
                  <h4 className="text-dark text-md">Ao votar você receberá um presente aleatório</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Label tag={'span'} htmlFor="minecraftusername">Nome do jogador</Label>
                      <input autoComplete="given-name" name="mineusername" id="minecraftusername" required onChange={(e) => setMineUser(e.target.value)} defaultValue={sessionStorage.getItem('mineuser')} className="mb-2 w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline mt-2" type="text" placeholder="Usuário do minecraft"/>
                    </Col>
                    <Col md="12" className="items-center">
                      <div className="flex items-center mb-4">
                        <input id="default-checkbox" type="checkbox" onChange={(e) => {
                          setCheckBox(e.target.checked)
                          setDescription(false)
                        }}
                        className="w-4 h-4 text-blue-600  rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"/>
                        <label htmlFor="default-checkbox" className="ml-2 mb-0 text-sm font-medium text-gray-900 dark:text-gray-300">Deixar uma avaliação?</label>
                      </div>
                    </Col>
                    {checkbox &&
                      <Col md="12" className="mb-3">
                        <Label tag={'span'}>
                          <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Dê sua opinião.</label>
                          <textarea required onChange={(e) => {setDescription(e.target.value)}} id="message" rows="4" className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 ring-0  ${checkbox && description === '' ? 'border-red-600' : ''}`} placeholder="Descreva sua opinião sobre o nosso servidor"></textarea>
                          {checkbox && description === '' &&
                            <label htmlFor="message" className="block mb-2 text-sm font-medium text-red-600 dark:text-white">Preencha o campo de comentário para prosseguir.</label>
                          }
                        </Label>
                      </Col>
                    }
                    <Col md="6">
                      <Label tag={'span'}>
                        Avalie nosso servidor. <b>Escolha até 5 estrelas</b>
                        <ReactStars {...starsConfig} />
                      </Label>
                    </Col>
                    <Col md={`${checkbox ? 6: 12}`}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        style={{float:"right"}}
                        sitekey={process.env.REACT_APP_CAPTCHA_TOKEN}
                        onChange={(e) => setTokenCaptcha(e)}
                      />
                    </Col>
                    <Col md="12" className="text-center">
                      <h2 className="text-dark font-bold text-lg text-green-800">{message}</h2>
                    </Col>
                    <Col md="12">
                      <button className="btn btn-success float-right" onClick={() => validateAndSendData()}>Confirmar</button>
                    </Col>
                    <Col md="12" className="text-center">
                     {voteList && voteList.map((value, i) => {
                        return (
                          <Card key={i} className="bg-white text-dark text-lg text-green-800 shadow-xl">
                            <CardHeader>
                              <CardTitle>
                                <ReactStars
                                  size={24}
                                  count={5}
                                  edit={false}
                                  color="#a8a8a8"
                                  activeColor="#ffd700"
                                  value={value.stars}
                                  a11y={true}
                                  classNames="text-lg"
                                  isHalf={true}
                                  emptyIcon={<i className="far fa-star" />}
                                  halfIcon={<i className="fa fa-star-half-alt" />}
                                  filledIcon={<i className="fa fa-star" />}
                                />
                              </CardTitle>
                              <div className="d-flex w-100 text-md text-dark mr-1 items-center align-middle ">
                                <img className={`w-12 h-12 mt-3 mr-2`} src={'https://minotar.net/cube/'+ value.mineUser +'/100.png'}/>
                                <h2 className="text-dark font-bold">{value.mineUser}</h2>
                              </div>
                            </CardHeader>
                            {value.description != 'false' &&
                              <CardBody>
                                <Row>
                                  <Col md="12" className="text-left">
                                    <h2 className="text-dark">{value.description}</h2>
                                  </Col>
                                </Row>
                              </CardBody>
                            }
                          </Card>
                        )
                      })
                     }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
