import axios from "axios";
import { getToken } from "./auth";
// import { appWebSocketRegistry } from "utils/websockets";
export const baseURL = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL: baseURL
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

export default api;

// export const appWebSocket = appWebSocketRegistry('/ws/')
