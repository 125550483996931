export const money = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

export const roundTo = (n, digits) => {
  if (digits === undefined) {
    digits = 0;
  }

  var multiplicator = Math.pow(10, digits);
  n = parseFloat((n * multiplicator).toFixed(11));
  var test =(Math.round(n) / multiplicator);
  return +(test.toFixed(digits));
}
