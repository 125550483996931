import React from "react";
import CookieConsent from "react-cookie-consent"
import { toast } from "react-toastify";

const CookieControl = () => {
  // console.log(getCookieConsentValue('cookiesconsent'));
  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Aceitar"
        cookieName="cookiesconsent"
        declineButtonText="Recusar"
        enableDeclineButton
        style={{ background: "#32325d" }}
        buttonStyle={{ color: "#000000"}}
        declineButtonClasses="rounded m-1"
        buttonClasses="rounded m-1"
        contentClasses="m-1 mb-0"
        expires={365}
        onAccept={() => {
          toast.success("Você aceitou usar os cookies!")
        }}
        onDecline={() => {
          toast.warning("Você recusou usar os cookies!")
        }}
      >
        Usamos cookies para aprimorar a experiência do usuário.{" "}
        <a className="italic hover:text-blue-500" href="/adsterms" target="_blank">{" "}Clique aqui para ler os termos</a>
      </CookieConsent>
    </>
  )
}

export default React.memo(CookieControl)
