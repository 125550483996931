/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

import {
  Row,
  Col,
  Container,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import api from "../../services/api";


export default function LandingPage() {
  const [name, setName] = useState(null)
  const [icon, setIcon] = useState(null)
  const [description, setDescription] = useState('')

  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    getDataCompany()
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };

  },[]);

  const getDataCompany = () => {
    api.get('company').then((response) => {
      setName(response.data.name)
      setIcon(response.data.icon)
      setDescription(response.data.description)
    })
  }

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section mt-52 mb-20">
          <Container>
            <Row>
              <Col md="12" className="mb-3">
                <h1 className="text-2xl">{name}</h1>
              </Col>
              <Col md="8">
                <div className="no-tailwindcss-base text-lg" dangerouslySetInnerHTML={{__html: description }} />
              </Col>
              <Col md="4" className="no-tailwindcss-base text-center items-center">
                { icon && <img alt="image_header"width="75%" height="75%" className="img-fluid" src={process.env.REACT_APP_API_URL + '/' + icon ?? require("assets/img/etherum.png").default} /> }
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
