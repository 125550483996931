/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";

import api from "../../../services/api";
import { BeatLoader } from "react-spinners";
import default_avatar from "../../../assets/img/default-avatar.png";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Switch } from '@headlessui/react';
import { toast } from "react-toastify";
import Tinymce from "components/RichText/Tinymce";
import DinamicInputs from "./Components/DinamicInputs";

export default function ProductCreate(props) {

  const animatedComponents = makeAnimated();
  const [title, setTitle] = React.useState('')
  const [description, setDescription] = React.useState('')
  const [picture_url, setPicture_url] = React.useState(null)
  const [picture_url_reference, setPicture_url_reference] = React.useState(null)
  const [currency_id, setCurrency_id] = React.useState('')
  const [price, setPrice] = React.useState('')
  const [unit_price, setUnit_price] = React.useState('')
  const [quantity, setQuantity] = React.useState('')
  const [commands, setCommands] = React.useState('')
  const [categoryId, setCategoryId] = React.useState('')
  const [quantitySelect, setQuantitySelect] = React.useState('')
  const [loader, setLoader] = React.useState(false)
  const [categories, setCategories] = React.useState(false)
  const fileInputSend = React.useRef(null);
  const [createProducts, setCreateProducts] = React.useState('')
  const [enabled, setEnabled] = React.useState(true)

  useEffect(() => {
    if (props.onChange) {
      props.onChange([createProducts])
    }
    getCategories()
    // eslint-disable-next-line
  }, [createProducts])

  const productSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    setUnit_price((parseFloat(price.replace(',', '.')) / quantity).toFixed(2))
    form.append('title', title)
    form.append('description', description)
    if (picture_url_reference){
      form.append('picture_url', picture_url_reference, picture_url_reference.name)
    }else{
      toast.error('Adicione uma imagem')
    }
    form.append('currency_id', currency_id)
    form.append('price', price)
    form.append('unit_price', unit_price)
    form.append('quantity', quantity)
    form.append('commands', JSON.stringify(commands))
    form.append('categoryId', categoryId)
    form.append('position', props.position)

    if(enabled){
      setQuantitySelect(1)
      form.append('quantity_select', quantitySelect)
    }else{
      form.delete('quantity_select')
      setQuantitySelect(null)
    }
    if(picture_url_reference || currency_id || categoryId){
      createProduct(form);
    }else{
      toast.error('Preencha todos os campos para continuar')
    }

    setLoader(false)
  }

  const createProduct = async (form) => {
    await api.post('product', form, {
      headers: {'content-type': 'multipart/form-data'},
      onUploadProgress: () => {
        setLoader(true)
      }
    }).then(() => {
      setLoader(false)
      setCreateProducts(true)
    }
    ).catch(() => {
      setLoader(false)
    })
  }

  const getCategories = () => {
    api.get('category-list').then((response) => {
      var listCategories = []
      response.data.map((value, i) => {
        return listCategories[i] = { value: value.id, label: value.Server.name + ' -> ' + value.name }
      })
      setCategories(listCategories)
    })
  }

  const handleImageChange = async (e) => {
    setPicture_url_reference(e.target.files[0])
    setPicture_url(URL.createObjectURL(e.target.files[0]))
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      color: '#000',
    }),
    container: provided => ({ ...provided, zIndex: 9999 }),
  }

  const currency = [
    { value: "BRL", label: "BRL" },
  ]


  const resultTextArea = data => {
    setDescription(data)
  }

  const commandsEventHandler = data => {
    setCommands(data)
  }

  return (
    <>
      <Card className="card-user bg-transparent shadow-none m-0">
        <div className="author mt-0">
          <a href="#a" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="avatar p-0 d-inline"
              src={picture_url ?? default_avatar}
              onClick={() => fileInputSend.current.click()}
            />
          </a>
          <div className="text-center">
            <input
              type="file"
              id="image"
              accept="image/png, image/jpeg, image/gif, image/webp"
              onChange={handleImageChange}
              ref={fileInputSend}
              hidden
            />
          </div>
        </div>
      </Card>
      <Card className="shadow-none bg-white">
        <CardBody className="bg-white p-0 mb-2">
          <Row>
            <Col md="12">
              <Label className="text-left text-dark">
                Titulo
              </Label>
              <input required onChange={(e) => setTitle(e.target.value)} className="w-full h-10 px-3 text-base text-gray-800 placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Titulo"/>
            </Col>
            <Col md="3">
              <Label className="text-left text-gray-800" >
                <span className="text-gray-800">Moeda</span>
              </Label>
              <Select
                placeholder="Moeda"
                onChange={e => setCurrency_id(e.value)}
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={currency}
                styles={customStyles}
              >
              </Select>
            </Col>
            <Col md="3">
              <Label className="text-left">
                <span className="text-gray-800">Categoria</span>
              </Label>
              <Select
                placeholder="Categoria"
                onChange={e => {setCategoryId(e.value)}}
                closeMenuOnSelect={true}
                components={animatedComponents}
                options={categories}
                styles={customStyles}
              ></Select>
            </Col>
            <Col md="3">
              <Label className="text-left">
                <span className="text-gray-800">Valor</span>
              </Label>
              <input required onChange={(e) => setPrice(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" type="text" placeholder="Preço"/>
            </Col>
            <Col md="3">
              <Label className="text-left">
                <span className="text-gray-800">Quantidade</span>
              </Label>
              <input type="number" required onChange={(e) => setQuantity(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 border-2 rounded-lg focus:shadow-outline" placeholder="Quantidade"/>
            </Col>
            <Col md="12" className="text-left">
              <Label className="text-dark">
                Descrição
              </Label>
              <Tinymce onChange={resultTextArea}/>
            </Col>
            <Col md="12">
              <DinamicInputs servers={props.servers} onChange={commandsEventHandler}/>
            </Col>
            <Col md="6">
              <Label className="text-left">
                <span className="text-gray-800">Alterar quantidade</span>
              </Label>
                <Switch.Group>
                <div className="flex items-center">
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={`${
                      enabled ? 'bg-blue-600' : 'bg-gray-200'
                    } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2`}
                  >
                    <span
                      className={`${
                        enabled ? 'translate-x-6' : 'translate-x-1'
                      } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                    />
                  </Switch>
                </div>
              </Switch.Group>
            </Col>
          </Row>
          <Row className="text-right">
            <Col md="12">
              {loader ?
                  <BeatLoader margin={10} color={'#3eda96'} loading={true} size={15} />
                :
                  <Button className="btn btn-success m-0" onClick={productSubmit}>
                    Confirmar
                  </Button>
              }
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
