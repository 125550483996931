import React, { Fragment } from "react";
import Tinymce from "components/RichText/Tinymce";
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row, Table } from "reactstrap";
import ToastNotify from "utils/toasts"
import { firstDateLocalTimezone } from "utils/dateControl"
import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import { BeatLoader } from "react-spinners";
import api from "services/api";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Footer from "components/Footer/Footer";
import { Dialog, Transition } from '@headlessui/react'

const InsertUpdates = () => {
  const [description, setDescription] = React.useState(null)
  const [dataUpdate, setDataUpdate] = React.useState(false)
  const [dataUpdateRemove, setDataUpdateRemove] = React.useState(false)
  const [loader, setLoader] = React.useState(false)
  const [updates, setUpdates] = React.useState(false)
  const [isOpenRemove, setIsOpenRemove] = React.useState(false)

  useEffect(() => {
    getUpdates()
    // eslint-disable-next-line
  }, [])

  const updateOrCreateUpdates = async () => {
    let creatOrUpdate = {}
    creatOrUpdate.description = description
    creatOrUpdate.id = dataUpdate.id
    await api.put('manage-update', creatOrUpdate).then((response) => {
      toast.success(response.data.message)
    })
    getUpdates()
  }

  const getUpdates = async () => {
    setLoader(true)
    await api.get('update').then((response) => {
      setUpdates(response.data)
      setDataUpdate(response.data.find(update => update.id === dataUpdate.id))
      setLoader(false)
    }).catch(() => setLoader(false))
  }

  const resultTextArea = data => {
    setDescription(data)
  }

  const updateRemove = async (e) => {
    e.preventDefault();
    setLoader(true)
    await api.delete(`update/${dataUpdateRemove.id}`).then(() => {
      setLoader(false)
      getUpdates()
    }).catch(() => {
      setLoader(false)
      getUpdates()
    })
    setIsOpenRemove(false)


  }

  function openModalRemove() {
    setIsOpenRemove(true)
  }

  function closeModalRemove() {
    setIsOpenRemove(false)
  }

  const updateComponentText = () => {
    if(dataUpdate){
      return <Tinymce data={dataUpdate?.description} onChange={resultTextArea}/>
    }
    return ''
  }

  return (
    <>
      <ToastNotify/>
      <AdminNavbar />
      <div className="wrapper">
        <div className="section mt-36">
          <Container>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12" className="text-left text-gray-50 text-md">
                      <Label tag={'span'}>
                        <h3 className="mb-2">Postar atualizações / Editar última atualização</h3>
                      </Label>
                    </Col>
                    <Col md="12" className="text-left text-gray-50 text-md">
                      {!loader && updateComponentText() }
                    </Col>
                    <Col md="12" className="text-right">
                      {loader ?
                        <BeatLoader margin={20} color={'#3eda96'} loading={true} size={10} />
                      :
                        <Button className="btn btn-success float-right" onClick={() => updateOrCreateUpdates()}>Salvar</Button>
                      }
                      <Button className="btn btn-success float-right"
                        onClick={() => {
                          setDataUpdate(false)
                          setTimeout(() => {
                            setDataUpdate({description: undefined})
                          }, 100)
                        }}
                      >Novo</Button>
                    </Col>
                    <Col md="12" className="text-left text-gray-50 text-md">
                      {updates &&
                        <div
                          style={{
                            maxHeight: '800px',
                            overflowY: 'auto'
                          }}
                        >
                          <Table height="600">
                            <thead className="text-primary">
                              <tr>
                                <th>#</th>
                                <th>Data de criação</th>
                                <th>Data de edição</th>
                                <th>Opções</th>
                              </tr>
                            </thead>
                            <tbody>
                              {updates.map((update) => (
                                <tr key={update.id}>
                                  <td className="text-left"># {update.id}</td>
                                  <td className="text-left">{firstDateLocalTimezone(update.createdAt, 'DD-MM-YYYY HH:mm')}</td>
                                  <td className="text-left">{firstDateLocalTimezone(update.updatedAt, 'DD-MM-YYYY HH:mm')}</td>
                                  <td width="100" className="text-center">
                                    <button onClick={() => {
                                      setDataUpdate(false)
                                      setTimeout(() => {
                                        setDataUpdate(update)
                                      }, 100)
                                    }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </button>
                                    <button onClick={() => {
                                        setDataUpdateRemove(update)
                                        openModalRemove(true)
                                      }}
                                      className="ml-2"
                                    >
                                      <i className="text-red-500 fas fa-times fa-lg"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          </Container>
          <Transition appear show={isOpenRemove} as={Fragment}>
              <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={closeModalRemove}
              >
                <div className="min-h-screen px-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Dialog.Overlay className="fixed inset-0" />
                  </Transition.Child>

                  <span
                    className="inline-block h-screen align-middle"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                      <Dialog.Title
                        as="h3"
                        className="mb-3 text-lg font-medium leading-6 text-gray-900"
                      >
                        Remover cupom
                        <button type="button" onClick={closeModalRemove}  className="text-danger close" aria-label="Close"><span aria-hidden="true">×</span></button>
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Tem certeza que deseja remover o cupom?
                        </p>
                      </div>
                      <div className="d-flex">

                        <div className="mt-4">
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-green-500 border border-transparent rounded-md hover:bg-green-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={closeModalRemove}
                          >
                            Cancelar
                          </button>
                        </div>
                        <div className="mt-4">
                          <button
                            type="button"
                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-50 bg-red-500 border border-transparent rounded-md hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                            onClick={(e) => {updateRemove(e)}}
                          >
                            Remover
                          </button>
                      </div>
                      </div>
                    </div>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition>
        </div>
      <Footer />
      </div>
    </>
  )
}
export default InsertUpdates
