/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import {
  Row,
  Col,
  Container,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import {
  Timeline,
  Events,
  TextEvent,
  themes,
} from '@merc/react-timeline';
import { createTheme } from "@material-ui/core";

export default function RoadMap() {
  // const [name, setName] = useState(null)
  // const [icon, setIcon] = useState(null)
  // const [description, setDescription] = useState('')

  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    // getDataCompany()
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };

  },[]);

  // const getDataCompany = () => {
  //   api.get('company').then((response) => {
  //     setName(response.data.name)
  //     setIcon(response.data.icon)
  //     setDescription(response.data.description)
  //   })
  // }

  return (
    <>
    {/* https://react-timeline.com/?path=/docs/timeline-theming-intro--page */}
    {/* https://codesandbox.io/s/srpe2?file=/src/index.js:940-946 */}
      <ExamplesNavbar />
      <div className="wrapper">
        <div className="page-header header-filter">
          <img alt="path" className="path" src={require("assets/img/blob.png").default}/>
          <img alt="path2" className="path2" src={require("assets/img/path2.png").default}/>
          <img alt="shapes_triangle" className="shapes triangle" src={require("assets/img/triunghiuri.png").default}/>
          <img alt="shapes_wave" className="shapes wave" src={require("assets/img/waves.png").default}/>
          <img alt="shapes_squares" className="shapes squares" src={require("assets/img/patrat.png").default}/>
          <img alt="shapes_circle" className="shapes circle" src={require("assets/img/cercuri.png").default}/>
          <section className="section mt-80 mb-20">
            <Container>
              <div className="content-center brand">
                <h1 className="h1-seo">Metas de desenvolvimento</h1>
              </div>
            </Container>
          </section>
        </div>
        <section className="section mt-10 mb-20">
          <Row>
            <Col md="12" className="mb-3">
              <Timeline opts={{layout: "alt-evts-inline-date"}} theme={createTheme(themes.default, {
                card: {
                  backgroundColor: '#efefef',
                },
                date: {
                  backgroundColor: '#1d8cf8',
                },
                marker: {
                  borderColor: '#1d8cf8',
                },
                timelineTrack: {
                  backgroundColor: '#1d8cf8',
                },
              })}>
                <Events>
                  <TextEvent date="Primeira etapa" text="**Iniciado o desenvolvimento do site**" />
                  <TextEvent date="Segunda etapa" text="**Implementação, Analise e teste**" />
                  <TextEvent date="Terceira etapa" text="**Produção**" />
                  <TextEvent date="Quarta etapa" text="**Melhorias**" />
                  <TextEvent date="Quinta etapa" text="**Implementar telas novas no site e ajustes no servidor**" />
                </Events>
              </Timeline>
            </Col>
          </Row>
        </section>
        <Footer />
      </div>
    </>
  );
}
