import { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { Col } from 'reactstrap'

export const CustomClipLoader = (props) => {
  const [seconds, setSeconds] = useState('00')
  const clock = {
    totalSeconds: 0,
    start: function () {
      if (!this.interval) {
        const self = this
        function pad (val) { return val > 9 ? val : '0' + val }
        this.interval = setInterval(function () {
          self.totalSeconds += 1
          setSeconds(pad(parseInt(self.totalSeconds % 60)))
        }, 1000)
      }
    },
    reset: function () {
      clock.totalSeconds = null
      clearInterval(this.interval)
      setSeconds('00')
      delete this.interval
    }
  }

  const clockCounter = () => {
    if (typeof props.loader !== 'undefined') {
      if (props.loader) {
        clock.start()
      } else {
        clock.reset()
      }
    }
  }

  useEffect(() => {
    clockCounter()
    return () => {
      clock.reset()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Col lg="12" md="12" className='text-center'>
      <h3 className='pr-2'>Carregando... {typeof props.loader !== 'undefined' ? <span>{seconds}s</span> : ''}</h3>
      <ClipLoader margin={20} color={'#3eda96'} loading={true} size={25} />
    </Col>
  )
}
