/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";

import {
  Row,
  Col,
  Container,
  CardBody,
  CardHeader,
  Card,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import Footer from "components/Footer/Footer.js";
import api from "../../services/api";
import Goals from "views/IndexSections/Goals";


export default function Updates() {
  const [description, setDescription] = useState('')

  React.useEffect(() => {
    document.body.classList.toggle("landing-page");
    getDataCompany()
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page");
    };

  },[]);

  const getDataCompany = () => {
    api.get('update').then((response) => {
      setDescription(response.data)
    })
  }

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <section className="section mt-44 mb-20">
          <img alt="path3" className="path" src={require("assets/img/path4.png").default}
          />
          <Goals classCard="bg-white" classTitle="text-dark" classPercent="text-dark"></Goals>
          <Container>
              <Card className="bg-white">
                <CardHeader><h2 className="text-dark text-lg">Atualizações</h2></CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      {description && description.map((value, index) => {
                        return <div key={index} className="mb-5">
                          <h2 className="text-dark text-lg"># {value.id}</h2>
                          <div className="no-tailwindcss-base text-lg" dangerouslySetInnerHTML={{__html: value.description }} />
                        </div>
                      })}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
}
