import React from "react";
import Tinymce from "components/RichText/Tinymce";
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row } from "reactstrap";
import ToastNotify from "utils/toasts"
import AdminNavbar from "../../../components/Navbars/AdminNavbar";
import { BeatLoader } from "react-spinners";
import api from "services/api";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Goal = () => {
  const [description, setDescription] = React.useState('')
  const [icon, setIcon] = React.useState('')
  const [value, setValue] = React.useState('')
  const [loader, setLoader] = React.useState(false)

  useEffect(() => {
    getLastUpdate()
  }, [])

  const updateOrCreateUpdates = async () => {
    let creatOrUpdate = {}
    creatOrUpdate.description = description
    creatOrUpdate.icon = icon
    creatOrUpdate.value = value

    await api.post('goal', creatOrUpdate).then((response) => {
      toast.success(response.data.message)
    })
    getLastUpdate()
  }

  const getLastUpdate = async () => {
    setLoader(true)
    await api.get('goal').then((response) => {
      setDescription(response.data.description)
      setIcon(response.data.icon)
      setValue(response.data.value)
      setLoader(false)
    }).catch(() => setLoader(false))
  }

  const resultTextArea = data => {
    setDescription(data)
  }

  return (
    <>
      <ToastNotify/>
      <AdminNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                  </CardHeader>
                  <CardBody>
                    <Row>
                    <Col md="12" className="text-left text-gray-50 text-md">
                      <Label tag={'span'}>
                        <h3 className="mb-2">Criar/Editar Meta</h3>
                      </Label>
                    </Col>
                    <Col md="6" className="text-left text-gray-50 text-md pb-3">
                      <Label className="text-left">
                        <span className="text-gray-50">Valor</span>
                      </Label>
                      <input type="number" defaultValue={value} onChange={(e) => setValue(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 text-gray-800 border-2 rounded-lg focus:shadow-outline" placeholder="valor"/>
                    </Col>
                    <Col md="6" className="text-left text-gray-50 text-md">
                      <Label className="text-left">
                        <span className="text-gray-50">icone</span>
                      </Label>
                      <input type="text" defaultValue={icon} onChange={(e) => setIcon(e.target.value)} className="w-full h-10 px-3 text-base placeholder-gray-600 text-gray-800 border-2 rounded-lg focus:shadow-outline" placeholder="icone"/>
                    </Col>
                    <Col md="12" className="text-left text-gray-50 text-md">
                    <Label className="text-left">
                        <span className="text-gray-50">Descrição</span>
                      </Label>
                      {!loader && <Tinymce data={description} onChange={resultTextArea}/>}
                    </Col>
                    <Col md="12" className={`${!loader ? 'text-right': 'text-center'}`}>
                      {loader ?
                        <BeatLoader margin={20} color={'#3eda96'} loading={true} size={10} />
                      :
                        <Button className="btn btn-success float-right" onClick={() => updateOrCreateUpdates()}>Salvar</Button>
                      }
                    </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  )
}
export default Goal
