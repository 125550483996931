import { useEffect } from "react"
import { useState } from "react"
import { Card, CardBody, Col, Container, Progress, Row } from "reactstrap"
import api from "services/api"
import { roundTo } from "utils/functions"

const Goals = (props) => {
  const [goal, setGoal] = useState(props.goal ?? 0)

  useEffect(() => {
    getGoal()
  }, [])

  const getGoal = async () => {
    await api.get('goal-status').then((response) => setGoal(response.data))
  }
  const defineColor = () => {
    if(goal.percentGoal <= 20){
      return 'progress-danger'
    }else if (goal.percentGoal > 20 && goal.percentGoal < 60){
      return 'progress-warning'
    }else if (goal.percentGoal >= 60 && goal.percentGoal < 80){
      return 'progress-neutral'
    }else if (goal.percentGoal >= 80 && goal.percentGoal < 90){
      return 'progress-info'
    }else if (goal.percentGoal >= 90){
      return 'progress-success'
    }
  }

  const getDate = () => {
    var options = { year: 'numeric', month: 'long'};
    let date = new Date()
    return date.toLocaleDateString("pt-BR", options)

  }

  return (
    <div className="section section-store" id="store-section">
      <Container>
        <Card className={props.classCard}>
          <CardBody>
            <Row>
              <Col md="12">
                <div className={`progress-container ${defineColor()}`}>
                  <span className={`progress-badge ${props.classTitle}`}>{goal.percentGoal >= 100 ? "Atingimos a meta do mês, obrigado a todos!!" : `Meta do mês - ${getDate()}`}</span>
                  <Progress max="100" value={goal.percentGoal}>
                    <span className={`pt-2 progress-value ${props.classPercent}`}>{roundTo(goal.percentGoal, 2)}%</span>
                  </Progress>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default Goals
