import moment from "moment";
import 'moment/locale/pt-br';
moment.locale('pt-br');

export const localTimezone = (date, format) => {

  var dateFormat = format;
  var testDateUtc = moment(date);
  var localDate = testDateUtc.local();
  return localDate.format(dateFormat)
}

export const firstDateLocalTimezone = (date, format) => {
  var dateFormat = format;
  var testDateUtc = moment.utc(date);
  var localDate = testDateUtc.local().add(3, 'hour');
  return localDate.format(dateFormat)
}

export const formatDateTime = (diffTime) => {
  var year, month, day, hour, minute, second;
  second = Math.floor(diffTime / 1000);
  minute = Math.floor(second / 60);
  second = second % 60;
  hour = Math.floor(minute / 60);
  minute = minute % 60;
  day = Math.floor(hour / 24);
  hour = hour % 24;
  month = Math.floor(day / 30);
  day = day % 30;
  year = Math.floor(month / 12);
  month = month % 12;

  var formatedYear = ''
  var formatedMonth = ''
  var formatedDay = ''
  var formatedHour = ''
  var formatedMinute = ''
  var formatedSecond = ''

  if (year > 1){
    formatedYear = year + " anos : "
  }else if (year === 1){
    formatedYear = year + " ano : "
  }

  if (month > 1){
    formatedMonth = month + " meses : "
  }else if (month === 1){
    formatedMonth = month + " mês : "
  }

  if (day > 1){
    formatedDay = day + " dias : "
  }else if (day === 1){
    formatedDay = day + " dia : "
  }

  if (hour >= 1){
    formatedHour = hour + " h : "
  }
  if (minute >= 1){
    formatedMinute = minute + " min : "
  }

  if (second >= 1){
    formatedSecond = second + " seg"
  }

  return (formatedYear + formatedMonth + formatedDay + formatedHour + formatedMinute + formatedSecond)
}

export const diffDate = (end) => {
  const date1 = new Date(localTimezone(end, 'YYYY-MM-DD HH:mm'));
  const date2 = new Date();
  const diffTime = Math.abs(date2 - date1)

  if(date1 > date2){
    return ('irá expirar em '+ formatDateTime(diffTime))
  }
  if(formatDateTime(diffTime)){
    return ("expirado à " + formatDateTime(diffTime));
  }
}
