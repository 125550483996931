import { Component } from "react";
import { logout } from '../..//services/auth';
import { Redirect } from "react-router-dom";

class Logout extends Component {

    render () {
        logout()
        return (
            <Redirect to="/home" />
        );
    }
}
export default Logout;
