/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { CustomClipLoader } from "components/Loaders/CustomClipLoader";
import React, { lazy, Suspense } from "react";

// core components
// import IndexNavbar from "components/Navbars/IndexNavbar.js";
// import PageHeader from "components/PageHeader/PageHeader.js";
// import Store from "views/IndexSections/Store";
// import Votes from "views/IndexSections/Votes";
// import Members from "./IndexSections/Members";
// import Footer from "components/Footer/Footer.js";
// import Goals from "./IndexSections/Goals";
// import TopThree from "./IndexSections/TopThree";
// import TextAds from "./IndexSections/TextAds";
const IndexNavbar = lazy(() => import('components/Navbars/IndexNavbar'))
const PageHeader = lazy(() => import('components/PageHeader/PageHeader'))
const Store = lazy(() => import('views/IndexSections/Store'))
const Votes = lazy(() => import('views/IndexSections/Votes'))
const Members = lazy(() => import('./IndexSections/Members'))
const Footer = lazy(() => import('../components/Footer/Footer'))
const Goals = lazy(() => import('./IndexSections/Goals'))
const TopThree = lazy(() => import('./IndexSections/TopThree'))
const TextAds = lazy(() => import('./IndexSections/TextAds'))


// import SocialMedia from "./IndexSections/SocialMedia";
// import Download from "views/IndexSections/Download.js";
// sections for this page/view
// import Basics from "views/IndexSections/Basics.js";
// import Navbars from "views/IndexSections/Navbars.js";
// import Pagination from "views/IndexSections/Pagination.js";
// import Notifications from "views/IndexSections/Notifications.js";
// import Typography from "views/IndexSections/Typography.js";
// import JavaScript from "views/IndexSections/JavaScript.js";
// import NucleoIcons from "views/IndexSections/NucleoIcons.js";
// import Signup from "views/IndexSections/Signup.js";

export default function Index() {

  const [qantityCart, setQuantityCart] = React.useState(0);

  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };

  },[qantityCart]);

  const quantityCartEventHandler = data => {
    setQuantityCart(data)
  }

  return (
    <>
      <Suspense fallback={<CustomClipLoader/>}>
        <IndexNavbar qantityCart={qantityCart}/>
      </Suspense>
      <div className="wrapper">
        <Suspense fallback={<CustomClipLoader/>}>
          <PageHeader />
        </Suspense>
        <div className="main">
          {/* <Basics /> */}
          {/* <Navbars /> */}
          <Suspense fallback={<CustomClipLoader/>}>
            <Goals />
          </Suspense>
          <Suspense fallback={<CustomClipLoader/>}>
            <Store onChange={quantityCartEventHandler} />
          </Suspense>
          <Suspense fallback={<CustomClipLoader/>}>
            <TopThree />
          </Suspense>
          {/* <Pagination /> */}
          {/* <Notifications /> */}
          {/* <Typography /> */}
          {/* <JavaScript /> */}
          {/* <NucleoIcons /> */}
          {/* <Signup /> */}
          <Suspense fallback={<CustomClipLoader/>}>
            <Votes />
          </Suspense>
          <Suspense fallback={<CustomClipLoader/>}>
            <TextAds />
          </Suspense>
          <Suspense fallback={<CustomClipLoader/>}>
            <Members />
          </Suspense>
          {/* <Download /> */}
        </div>
        <Suspense fallback={<CustomClipLoader/>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
}
